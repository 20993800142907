@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url('./static/font/webfont/webfont.eot'); /* IE9 */
  src: url('./static/font/webfont/webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./static/font/webfont/webfont.woff2') format('woff2'),
  url('./static/font/webfont/webfont.woff') format('woff'), /* chrome、firefox */
  url('./static/font/webfont/webfont.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('./static/font/webfont/webfont.svg#webfont') format('svg'); /* iOS 4.1- */
}

body {
  margin: 0;
  padding: 0;
  font-family: "webfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
