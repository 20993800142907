@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url(../../static/media/webfont.650909fa.eot); /* IE9 */
  src: url(../../static/media/webfont.650909fa.eot?#iefix) format('embedded-opentype'), 
  url(../../static/media/webfont.d673df8e.woff2) format('woff2'),
  url(../../static/media/webfont.bb26fee4.woff) format('woff'), 
  url(../../static/media/webfont.1c153ae2.ttf) format('truetype'), 
  url(../../static/media/webfont.ebe11bd0.svg#webfont) format('svg'); /* iOS 4.1- */
}

body {
  margin: 0;
  padding: 0;
  font-family: "webfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.index_table-row-active__1HhY7 td {
  position: relative;
  border-bottom: 1px solid #a5ceef !important;
  background-color: #cfe8fd !important;
}
.index_table-row-active__1HhY7 td::before {
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #a5ceef;
}
.skin-default .index_loading__31wA6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}
.skin-red .index_loading__31wA6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 200px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}

.page404_module__3u8lZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.page404_module__3u8lZ p {
  color: #c4c4c4;
  font-size: 150px;
  letter-spacing: 10px;
}

.index_table-row-active__x7dc7 td {
  position: relative;
  border-bottom: 1px solid #a5ceef !important;
  background-color: #cfe8fd !important;
}
.index_table-row-active__x7dc7 td::before {
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #a5ceef;
}
.skin-default .index_drawer-footerBtns__YenUb {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: left;
}
.skin-default .index_drawer-footerBtns__YenUb button {
  margin-right: 8px;
}
.skin-default .index_header__LGLvu {
  height: 84px;
  position: fixed;
  top: 0;
  right: 0;
  left: 256px;
  z-index: 999;
  background: #ffffff;
  transition-duration: 200ms;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.skin-default .index_header-left__3D8_I {
  display: inline-block;
  width: 200px;
  height: 64px;
  vertical-align: top;
}
.skin-default .index_header-left__3D8_I img {
  height: 100%;
}
.skin-default .index_header-right__5I1kz {
  display: inline-block;
}
.skin-default .index_header-right__5I1kz span.index_trigger__3BGS9 {
  height: 64px;
  padding: 19px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.skin-default .index_header-right__5I1kz span.index_platform-title__2Q1-t {
  font-size: 30px;
  color: black;
  font-weight: 800;
}
.skin-default .index_header-right__5I1kz div.index_header-right-components__aHalJ {
  position: absolute;
  top: 0;
  right: 30px;
}
.skin-default .index_header-right__5I1kz div.index_header-right-components__aHalJ div {
  display: inline-block;
}
.skin-default .index_header-right__5I1kz div.index_header-right-components__aHalJ div:first-child {
  margin-right: 15px;
}
.skin-default ul.index_user__2NRVk {
  margin: 0;
  padding: 0;
}
.skin-default ul.index_user__2NRVk li {
  list-style: none;
  cursor: pointer;
}
.skin-default ul.index_user__2NRVk li i {
  margin-right: 5px;
}
.skin-default ul.index_user__2NRVk li:hover {
  color: #1890ff;
}
.skin-default ul.index_message-remind__39VWw {
  margin: -12px -16px;
  padding: 0;
}
.skin-default ul.index_message-remind__39VWw li {
  list-style: none;
}
.skin-default ul.index_message-remind__39VWw li div {
  display: block;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
}
.skin-default ul.index_message-remind__39VWw li div img {
  width: 20px;
  margin-right: 5px;
  vertical-align: -4px;
}
.skin-default ul.index_message-remind__39VWw li div:hover {
  color: #1890ff;
  background-color: #e6f7ff;
}
.skin-red .index_drawer-footerBtns__YenUb {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: left;
}
.skin-red .index_drawer-footerBtns__YenUb button {
  margin-right: 8px;
}
.skin-red .index_header__LGLvu {
  height: 84px;
  position: fixed;
  top: 0;
  right: 0;
  left: 256px;
  z-index: 999;
  background: #ffffff;
  transition-duration: 200ms;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.skin-red .index_header-left__3D8_I {
  display: inline-block;
  width: 200px;
  height: 64px;
  vertical-align: top;
}
.skin-red .index_header-left__3D8_I img {
  height: 100%;
}
.skin-red .index_header-right__5I1kz {
  display: inline-block;
}
.skin-red .index_header-right__5I1kz span.index_trigger__3BGS9 {
  height: 64px;
  padding: 19px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
}
.skin-red .index_header-right__5I1kz span.index_platform-title__2Q1-t {
  font-size: 30px;
  color: black;
  font-weight: 800;
}
.skin-red .index_header-right__5I1kz div.index_header-right-components__aHalJ {
  position: absolute;
  top: 0;
  right: 30px;
}
.skin-red .index_header-right__5I1kz div.index_header-right-components__aHalJ div {
  display: inline-block;
}
.skin-red .index_header-right__5I1kz div.index_header-right-components__aHalJ div:first-child {
  margin-right: 15px;
}
.skin-red ul.index_user__2NRVk {
  margin: 0;
  padding: 0;
}
.skin-red ul.index_user__2NRVk li {
  list-style: none;
  cursor: pointer;
}
.skin-red ul.index_user__2NRVk li i {
  margin-right: 5px;
}
.skin-red ul.index_user__2NRVk li:hover {
  color: #1890ff;
}
.skin-red ul.index_message-remind__39VWw {
  margin: -12px -16px;
  padding: 0;
}
.skin-red ul.index_message-remind__39VWw li {
  list-style: none;
}
.skin-red ul.index_message-remind__39VWw li div {
  display: block;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
}
.skin-red ul.index_message-remind__39VWw li div img {
  width: 20px;
  margin-right: 5px;
  vertical-align: -4px;
}
.skin-red ul.index_message-remind__39VWw li div:hover {
  color: #1890ff;
  background-color: #e6f7ff;
}

.index_table-row-active__5ZDUm td {
  position: relative;
  border-bottom: 1px solid #a5ceef !important;
  background-color: #cfe8fd !important;
}
.index_table-row-active__5ZDUm td::before {
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #a5ceef;
}
.skin-default .index_menu-logo__13Yje {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
}
.skin-default .index_menu-logo__13Yje img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.skin-default .index_menu-logo__13Yje h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.skin-default .index_menu__1pyrA {
  background-color: #001529;
}
.skin-default .index_menu__1pyrA ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.skin-default .index_menu__1pyrA ul li {
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-bottom: 1px solid #3b3b43;
  cursor: pointer;
}
.skin-default .index_menu__1pyrA ul li a {
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.skin-default .index_menu__1pyrA ul li a:hover {
  background: #6dcff6;
}
.skin-default .index_menu__1pyrA ul li a.index_list-focus__2oP0i {
  background: #6dcff6;
}
.skin-red .index_menu-logo__13Yje {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
}
.skin-red .index_menu-logo__13Yje img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.skin-red .index_menu-logo__13Yje h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.skin-red .index_menu__1pyrA {
  background-color: #001529;
}
.skin-red .index_menu__1pyrA ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.skin-red .index_menu__1pyrA ul li {
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-bottom: 1px solid #3b3b43;
  cursor: pointer;
}
.skin-red .index_menu__1pyrA ul li a {
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.skin-red .index_menu__1pyrA ul li a:hover {
  background: #6dcff6;
}
.skin-red .index_menu__1pyrA ul li a.index_list-focus__2oP0i {
  background: #6dcff6;
}

.index_table-row-active__1tcBu td {
  position: relative;
  border-bottom: 1px solid #a5ceef !important;
  background-color: #cfe8fd !important;
}
.index_table-row-active__1tcBu td::before {
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #a5ceef;
}
.skin-default .index_tabs__goAzP {
  width: 100%;
  position: absolute;
  top: 42px;
  left: 0;
  padding: 0 7px;
  z-index: 999;
}
.skin-default .index_tabs__goAzP .index_wrap__3QGsP {
  position: relative;
  top: 12px;
  left: 0;
  background-color: white;
  border-radius: 2px;
  border: 1px #e9e9e9 solid;
}
.skin-default .index_tabs__goAzP .index_wrap__3QGsP .index_sonTabs__2O-Ja {
  width: 100%;
  height: 60px;
}
.skin-default .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A {
  position: absolute;
  top: 14px;
  right: 16px;
}
.skin-default .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A > span:not(:first-child) {
  margin-left: 20px;
}
.skin-default .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A .index_iconHover__e697Y:hover {
  color: #1890ff;
}
.skin-default .index_tabs__goAzP .ant-tabs-bar {
  padding-left: 56px;
}
.skin-default .index_tabs__goAzP .ant-tabs-bar {
  margin: 0;
  border-bottom: none;
}
.skin-red .index_tabs__goAzP {
  width: 100%;
  position: absolute;
  top: 42px;
  left: 0;
  padding: 0 7px;
  z-index: 999;
}
.skin-red .index_tabs__goAzP .index_wrap__3QGsP {
  position: relative;
  top: 12px;
  left: 0;
  background-color: white;
  border-radius: 2px;
  border: 1px #e9e9e9 solid;
}
.skin-red .index_tabs__goAzP .index_wrap__3QGsP .index_sonTabs__2O-Ja {
  width: 100%;
  height: 60px;
}
.skin-red .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A {
  position: absolute;
  top: 14px;
  right: 16px;
}
.skin-red .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A > span:not(:first-child) {
  margin-left: 20px;
}
.skin-red .index_tabs__goAzP .index_wrap__3QGsP .index_smallTools__33N1A .index_iconHover__e697Y:hover {
  color: #1890ff;
}
.skin-red .index_tabs__goAzP .ant-tabs-bar {
  padding-left: 56px;
}
.skin-red .index_tabs__goAzP .ant-tabs-bar {
  margin: 0;
  border-bottom: none;
}

span.ant-input-suffix{
      /* visibility: hidden; */
      opacity: 0;
}
.ant-input-affix-wrapper input:focus+span.ant-input-suffix{
      /* visibility: visible; */
      opacity: 1;
}
.ant-table-pagination.ant-pagination{
      float:inherit!important;
}
